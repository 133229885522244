define("ui/components/cru-registry/component", ["exports", "shared/mixins/view-new-edit", "shared/mixins/optionally-namespaced", "ui/components/cru-registry/template", "ui/models/dockercredential"], function (_exports, _viewNewEdit, _optionallyNamespaced, _template, _dockercredential) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var TEMP_NAMESPACE_ID = '__TEMP__';

  var _default = Ember.Component.extend(_viewNewEdit.default, _optionallyNamespaced.default, {
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    scopeService: Ember.inject.service('scope'),
    layout: _template.default,
    model: null,
    titleKey: 'cruRegistry.title',
    scope: 'project',
    namespace: null,
    asArray: null,
    projectType: 'dockerCredential',
    namespacedType: 'namespacedDockerCredential',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (Ember.get(this, 'model.type') === 'namespacedDockerCredential') {
        Ember.set(this, 'scope', 'namespace');
      }

      var globalRegistryEnabled = Ember.get(this, 'globalStore').all('setting').findBy('id', 'global-registry-enabled') || {};
      Ember.set(this, 'globalRegistryEnabled', Ember.get(globalRegistryEnabled, 'value') === 'true');
      var asArray = JSON.parse(JSON.stringify(Ember.get(this, 'model.asArray') || []));

      if (!globalRegistryEnabled && Ember.get(this, 'mode') === 'new') {
        asArray = asArray.map(function (item) {
          if (item.preset === Ember.get(_this, 'hostname')) {
            return _objectSpread({}, item, {
              preset: 'custom'
            });
          }

          return item;
        });
      }

      Ember.set(this, 'asArray', asArray);
    },
    arrayChanged: Ember.observer('asArray.@each.{preset,address,username,password,auth}', function () {
      var registries = {};
      Ember.get(this, 'asArray').forEach(function (obj) {
        var preset = Ember.get(obj, 'preset');
        var key = Ember.get(obj, 'address');

        if (_dockercredential.PRESETS_BY_NAME[preset]) {
          key = _dockercredential.PRESETS_BY_NAME[preset];
        }

        var val = {};
        ['username', 'password', 'auth'].forEach(function (k) {
          var v = Ember.get(obj, k);

          if (v) {
            val[k] = v;
          }
        });
        registries[key] = val;
      });
      Ember.set(this, 'model.registries', registries);
      return this._super.apply(this, arguments);
    }),
    hostname: window.location.host,
    willSave: function willSave() {
      var pr = this.primaryResource;
      var tempSet = false;

      if (Ember.isEmpty(Ember.get(pr, 'namespaceId'))) {
        // Namespace is required, but doesn't exist yet... so lie to the validator
        Ember.set(pr, 'namespaceId', TEMP_NAMESPACE_ID);
        tempSet = true;
      }

      var ok = this.validate();

      if (tempSet) {
        // unset temp so that namespacePromise can takeover from here
        Ember.set(pr, 'namespaceId', null);
      }

      return ok;
    },
    validate: function validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];

      if (Ember.get(this, 'scope') === 'namespace' && Ember.isEmpty(Ember.get(this, 'primaryResource.namespaceId'))) {
        errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },
    doSave: function doSave() {
      var _arguments = arguments;
      var self = this;
      var sup = self._super;
      return this.namespacePromise().then(function () {
        return sup.apply(self, _arguments);
      });
    },
    doneSaving: function doneSaving() {
      if (this.done) {
        this.done();
      }
    }
  });

  _exports.default = _default;
});